import styled from '@emotion/styled'
import { blue, white, monserrat } from '../../assets/styles/styles'

const Wrapper = styled.div`
  position: fixed;
  left: 20px;
  bottom: 20px;
  padding: 5px 15px;
  border-radius: 15px;
  font-family: ${monserrat};
  font-weight: bold;
  font-size: 14px;
  background-color: #26D02F;
  color: ${white};
  cursor: pointer;
  z-index: 10;
`

export { Wrapper }
