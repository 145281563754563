import React, { Fragment } from 'react'
import Header from '../../components/Header/Header'
import Copyright from '../../components/Copyright/Copyright'
import { withContext } from '../../utils/helper'
import { Container, Row, Col } from '../../components/Bootstrap/Bootstrap'
import dummy from '../../utils/dummy'
import Banner from './components/Banner'
import Blog from './components/Blog'
import Footer from '../../components/Footer/Footer'
import ajax from './ajax'
import Spinner from '../../components/Loader/Spinner'
import {
  Flex,
  Text,
  Image,
  PageWrapper,
  FlexCell,
} from '../../assets/styles/components'
import BestChoice from './components/BestChoice'
import ProductImageGroup from './components/ProductImageGroup'
import ProductRow from './components/ProductRow'
import ajaxProduct from '../Product/ajax'
import Sponsored from './components/Sponsored'
import { Promo } from './styles'
import Category from './components/Category'

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      errordata: false,
      banner: [],
      video: [],
      group: [],
      bestchoice: [],
      sponsored: {},
      promotext: {},
      bestchoicetext: {},
      isTablet: false,
    }
    this.fetchData = this.fetchData.bind(this)
    this.add = this.add.bind(this)
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    this.props.context.update('history', this.props.history)
    // setTimeout(() => this.props.context.update('showDandanLoader', false), 1000)
    this.fetchData()
    window.addEventListener('resize', this.resize)
    this.resize()
  }

  resize = () => {
    this.setState({
      isTablet: window.innerWidth >= 768 && window.innerWidth <= 1200,
    })
  }

  componentWillUnmount() {
    this.props.context.update('showDandanLoader', true)
    window.removeEventListener('resize', this.resize)
  }

  async fetchData() {
    const { token } = this.props.context.state
    this.setState({ loading: true, errordata: false })
    let res = await ajax.getHomepage(token)
    if (!res.success) {
      this.setState({ loading: false, errordata: true })
      return false
    }
    let banner = res.data.homepage_slider || []
    let group = res.data.product_group || []
    let video = res.data.content_slider || []
    let bestchoice = res.data.best_choice || []
    let sponsored = res.data.sponsored_content || []
    let bestchoicetext = res.data.best_choice_text.Data || {}

    this.setState({
      banner,
      group,
      video,
      bestchoice,
      sponsored,
      bestchoicetext,
      loading: false,
    })
    setTimeout(() => this.props.context.update('showDandanLoader', false), 1000)
  }
  async add(id, data) {
    const { token } = this.props.context.state
    const { qty } = this.state
    if (!token) {
      this.props.context.update('showLogin', true)
      return false
    }
    this.props.context.update('showLoading', true)

    let res = await ajaxProduct.addToCart(id, 1, token)

    if (!res.success) {
      this.props.context.update('showLoading', false)
      this.props.context.update('error', res.message)
      return false
    }
    this.props.context.update('showLoading', false)
    this.props.context.update('successCart', data)
    this.props.context.update('refetchCart', true)
  }
  render() {
    const {
      loading,
      errordata,
      banner,
      video,
      group,
      bestchoice,
      sponsored,
      bestchoicetext,
      isTablet,
    } = this.state
    return (
      <Fragment>
        <Header match={this.props.match} location={this.props.location} />

        <PageWrapper promo={this.props.context.state.promo.content}>
          <FlexCell>
            {loading ? (
              <Flex justifyContent="center" margin="30px 0">
                <Spinner className="fa-2x" />
              </Flex>
            ) : (
              <Container>
               
                <Row margin="15px -15px">
                  <Col md={12} padding="0 15px">
                    <Banner data={banner} />
                  </Col>
                  
                </Row>
                <Row margin="0 -15px 30px" alignItems="center">
                  <Col md={12} padding="0">
                    <Blog data={video} isTablet={isTablet} />
                  </Col>
                  
                </Row>
                <Row>
                  {group.map((g, i) => {
                    if (g.Picture) {
                      return (
                        <ProductImageGroup
                          key={i}
                          data={g.Picture}
                          add={this.add}
                        />
                      )
                    }
                    if (g.Group) {
                      return (
                        <ProductRow key={i} data={g.Group} add={this.add} />
                      )
                    }
                    return null
                  })}
                </Row>
                <Row>
                  <Category />
                </Row>
              </Container>
            )}
          </FlexCell>

          <FlexCell>
            <Footer location={this.props.location} />
            <Copyright />
          </FlexCell>
        </PageWrapper>
      </Fragment>
    )
  }
}

export default withContext(Home)
