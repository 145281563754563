import styled from '@emotion/styled'
import { blue, white, breakpoints, orange } from '../../assets/styles/styles'

const MenuHeader = styled.ul`
  margin: 0;
  padding: 0.5em 2em;
  background-color: ${white};
  position: relative;
  box-shadow: 0 1px 10px 0 rgba(155, 155, 155, 0.3);
  border-bottom: 1px solid #d2d3d4;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;

  // @media (max-width: ${breakpoints[0]}) {
  //   display: none;
  // }
`

const MenuHeaderMobile = styled.div`
  // display: none;
  background-color: ${blue};
  padding: 10px 15px;

  // @media (max-width: ${breakpoints[0]}) {
  //   display: block;
  // }
`

const MenuListWrapper = styled.div`
  width: 50%;
  background-color: ${white};
  z-index: 21;
  position: relative;
`

const MenuInline = styled.li`
  list-style: none;
  margin: 0 20px;
  cursor: pointer;
  &:hover {
    color: ${orange};
    div {
      color: ${orange};
    }
  }
`

const MenuWrapper = styled.div`
  position: absolute;
  left: 180px;
  top: 30px;
  z-index: 11;
  display: flex;
  width: 100%;
  height: 60vh;

  @media (max-width: ${breakpoints[0]}) {
    top: 34px;
  }
`

const MenuWrapperRight = styled.div`
  position: absolute;
  left: 650px;
  top: 30px;
  z-index: 11;
  display: flex;
  width: 100%;
  height: auto;

  @media (max-width: ${breakpoints[0]}) {
    top: 34px;
  }
`

const MenuFirst = styled.ul`
  background-color: ${white};
  margin: 0;
  padding: 0;
  width: 17%;
  border-right: 1px solid #ddd;
  overflow: auto;
  box-shadow: 0 1px 20px 0 rgba(155, 155, 155, 0.3);

  @media (max-width: ${breakpoints[0]}) {
    width: 100%;
  }
`
const MenuFirstList = styled.li`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  &:hover {
    background-color: ${blue};
    color: ${white};
    div {
      color: ${white};
    }
  }
`
const MenuSecond = styled.ul`
  background-color: ${white};
  margin: 0;
  padding: 12px 15px;

  width: 25%;
  // border-right: 1px solid #ddd;
  box-shadow: 0 1px 20px 0 rgba(155, 155, 155, 0.3);
  overflow: auto;

  @media (max-width: ${breakpoints[0]}) {
    width: 100%;
  }
`
const MenuSecondList = styled.li`
  cursor: pointer;
  padding: 5px 8px;
  margin: 5px 0;
  list-style: none;
  &:hover,
  &.active {
    background-color: ${blue};
    color: ${white};
    border-radius: 10px;
    div {
      color: ${white};
      border-color: ${blue};
    }
  }
  a:hover {
    text-decoration: none;
  }
`

const MenuThird = styled.ul`
  background-color: ${white};
  margin: 0;
  padding: 20px 30px;
  width: 20%;
  overflow: auto;
  box-shadow: 0 1px 20px 0 rgba(155, 155, 155, 0.3);

  @media (max-width: ${breakpoints[0]}) {
    padding: 12px 15px;
    width: 100%;
  }
`

const MenuThirdList = styled.li`
  list-style: none;
  width: 100%;
  // margin-bottom: 15px;

  a:hover {
    text-decoration: none;
  }

  @media (max-width: ${breakpoints[0]}) {
    width: 48%;
  }
`

const MenuMobileWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: auto;
  background-color: transparent;
  z-index: 20;
  top: 0;
  left: ${props => (props.open ? '0' : '100%')};
  transition: all 0.2s;
`

const MenuMobileList = styled.div`
  padding: 12px 15px;
`

export {
  MenuHeader,
  MenuHeaderMobile,
  MenuListWrapper,
  MenuInline,
  MenuWrapper,
  MenuWrapperRight,
  MenuFirst,
  MenuFirstList,
  MenuSecond,
  MenuSecondList,
  MenuThird,
  MenuThirdList,
  MenuMobileWrapper,
  MenuMobileList,
}
