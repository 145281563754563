import React, { useContext } from 'react'
import { Row, Col } from '../../../components/Bootstrap/Bootstrap'
import {
  Text,
  Flex,
  FlexCell,
  Separator,
  Image,
  Icon,
} from '../../../assets/styles/components'
import Checkbox from '../../../components/Checkbox/Checkbox'
import { Wrapper } from '../styles'
import { formatMoney, calculateDiscount } from '../../../utils/format'
import Stepper from '../../../components/Stepper/Stepper'
import Spinner from '../../../components/Loader/Spinner'
import { orange } from '../../../assets/styles/styles'
import { AppContext } from '../../../context/AppContext'
import { Link } from 'react-router-dom'
import { host } from '../../../utils/apihost'

const CartLeft = ({
  loading,
  data,
  checkall,
  checkItem,
  checkAllItem,
  changeQuantity,
  deleteCart,
}) => {
  const { update } = useContext(AppContext)
  return (
    <Col md={8} padding="0">
      <Wrapper>
        <Flex>
          <Checkbox
            checked={checkall}
            text="Choose all product"
            style={{ transform: 'scale(1.5)' }}
            onChange={checkAllItem}
          />
        </Flex>
        <Separator margin="15px 0" />

        {loading ? (
          <Flex justifyContent="center">
            <Spinner className="fa-2x" />
          </Flex>
        ) : data.length == 0 ? (
          <Flex justifyContent="center">
            <Text>No item yet</Text>
          </Flex>
        ) : (
          data.map((g, i) => {
            return (
              <Row
                margin="15px -15px"
                justifyContent="space-between"
                alignItems="center"
                key={i}
              >
                <Col xs={12} md={8}>
                  <Flex>
                    <FlexCell flex={0.1}>
                      <Checkbox
                        checked={g.checked}
                        onChange={() => checkItem(i)}
                      />
                    </FlexCell>
                    <FlexCell margin="0 15px 0" flex={0.2}>
                      <Image className="rounded" src={g.avatar} width="80px" />
                    </FlexCell>
                    <FlexCell flex={0.7}>
                      <a href={`/product/${g.slug}`}>
                        <Text fontSize="13px" fontWeight="bold" margin="0">
                          {g.name}
                        </Text>
                      </a>
                      <Text margin="0 0 3px" fontSize="13px">
                        {`${g.color_name ? 'Color ' + g.color_name : ''}${
                          g.color_name && g.size_name ? ', ' : ''
                        }${g.size_name ? 'Size ' + g.size_name : ''}`}
                      </Text>
                      <Flex>
                        <Text fontWeight="500">
                          Rp{' '}
                          {formatMoney(
                            calculateDiscount(
                              g.price,
                              g.discount_type,
                              g.current_discount,
                            ).price,
                          )}
                        </Text>
                        {g.current_discount ? (
                          <Text
                            color="#777"
                            textDecoration="line-through"
                            margin="0 10px 0"
                          >
                            Rp {formatMoney(g.price || 0)}
                          </Text>
                        ) : null}
                      </Flex>
                    </FlexCell>
                  </Flex>
                </Col>
                <Col xs={12} md={4}>
                  <Flex
                    justifyContent="flex-end"
                    mobileStyle={{ marginTop: 15 }}
                  >
                    <Stepper
                      value={g.qty}
                      onChange={e => changeQuantity(i, e)}
                    />
                    <Icon
                      className="fa fa-trash"
                      style={{ fontSize: 18 }}
                      color={orange}
                      margin="0 0 0 20px"
                      cursor="pointer"
                      onClick={() => {
                        update('confirmation', {
                          text: 'Remove item from cart?',
                          submitText: 'Remove',
                          submit: () => {
                            deleteCart(g.cart_id)
                            update('confirmation', null)
                          },
                        })
                      }}
                    />
                  </Flex>
                </Col>
              </Row>
            )
          })
        )}
      </Wrapper>
    </Col>
  )
}

export default CartLeft
