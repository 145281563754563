import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import {
  border,
  white,
  black,
  blue,
  breakpoints,
  grey,
} from '../../assets/styles/styles'
import { formControl } from '../Input/styles'

const ActionWrapper = styled.ul`
  background-color: ${white};
  position: absolute;
  box-shadow: 0 1px 20px 0 rgba(155, 155, 155, 0.2);
  top: 105%;
  z-index: 100;
  margin: 0;
  padding: 15px;
  border-radius: 5px;
  transition: all 0.2s;
  left: 0;
  display: block;
  width: 100%;
  padding: 12px;

  @media (max-width: ${breakpoints[0]}) {
    width: 100%;
  }
`

const HistoryList = styled.div`
  background-color: #ddd;
  font-size: 11px;
  padding: 5px 10px;
  display: inline-block;
  margin: 0 10px 10px 0;
  cursor: pointer;
`

const BrandList = styled.a`
  display: flex;
  cursor: pointer;
  padding: 0 10px 10px;
  align-items: center;
  &:hover {
    text-decoration: none;
    color: ${blue};
  }
`

const ProductList = styled.a`
  display: block;
  cursor: pointer;
  padding: 8px 0;
  border-bottom: 1px solid ${border};
  color: ${black};
  font-size: 12px;
  &:hover {
    text-decoration: none;
    color: ${blue};
  }
  &:last-child {
    border-bottom: 0;
  }
`

const Input = styled.input`
  ${formControl};
  padding: 8px 30px 8px 20px;
  margin: 0;
  width: ${props => (props.focused ? '100%' : '100%')};
  transition: 0.3s;
  background-color: ${white};
  box-shadow: none;
  border-radius: 10px;
  border: 1px solid ${grey};
  outline: none;
  @media (max-width: ${breakpoints[0]}) {
    width: 100%;
    margin: 0;
  }
`

export { ActionWrapper, HistoryList, BrandList, ProductList, Input }
