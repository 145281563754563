import styled from '@emotion/styled'
import { blue, white, black, breakpoints } from '../../assets/styles/styles'
import { Link } from 'react-router-dom'

const ProductListWrapper = styled.div`
  position: relative;
  width: 100%;
  flex: 0 0 20%;
  max-width: 20%;
  padding: 10px;

  @media (max-width: ${breakpoints[0]}) {
    flex: 0 0 50%;
    max-width: 50%;
  }
`

const ProductListStyled = styled.a`
  box-shadow: 0 1px 20px 0 rgba(155, 155, 155, 0.2);
  background-color: white;
  position: relative;
  display: block;
  border-radius: 10px;
  border: 1px solid #ddd;
  margin: 0px 0px 10px;
  padding: 10px;
  .btn-wrapper {
    display: none;
  }
  &:hover {
    text-decoration: none;
    .btn-wrapper {
      display: block;
    }
  }
`

const CategoryWrapper = styled.div`
  max-height: 30vh;
  overflow: auto;
  width: 100%;
`

const CategoryList = styled.div`
  color: ${props => (props.active ? blue : black)};
  cursor: pointer;
  &:hover {
    color: ${blue};
  }
`

const ImageWrapper = styled.div`
  width: 100%;
  position: relative;
`

const Color = styled.div`
  border: ${props => (props.active ? '1px solid #ddd' : `1px solid ${white}`)};
  padding: 8px 10px 5px;
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;
  background-color: ${white};
`

const ColorBullet = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: block;
  margin: 0 auto;
  background-color: ${props => props.backgroundColor || white};
`

const Tab = styled.div`
  padding: 12px 30px;
  text-align: center;
  cursor: pointer;
  font-size: 15px;
  margin-right: 20px;
  font-weight: 500;
  transition: 0.2s;
  border-bottom: ${props =>
    props.active ? `2px solid ${blue}` : `2px solid ${white}`};
`

const PaginationNumber = styled.div`
  font-size: 15px;
  margin: 0 7px;
  cursor: pointer;
  &.active {
    font-weight: bold;
    color: ${blue};
  }
`

export {
  ProductListStyled,
  CategoryWrapper,
  CategoryList,
  ImageWrapper,
  Color,
  ColorBullet,
  Tab,
  ProductListWrapper,
  PaginationNumber,
}
