import styled from '@emotion/styled'
import { white, orange, breakpoints } from '../../assets/styles/styles'
import { Container } from '../Bootstrap/Bootstrap'

const HeaderTop = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 12;
`

const HeaderStyled = styled(Container)`
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  padding: 1.2em 2em;
  background-color: #FDDFC9;
  color: ${white};
  position: relative;
  z-index: 12;
  border-bottom: 1px solid #d2d3d4;
  box-shadow: 0 1px 5px 0 rgba(155, 155, 155, 0.3);

  @media (max-width: ${breakpoints[0]}) {
    padding: 10px 20px;
  }
`

export { HeaderTop, HeaderStyled }
