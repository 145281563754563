import React, { Fragment } from 'react'
import { ActionWrapper, UserMenuList, UserWrapper } from './styles'
import { white, orange, grey2, black } from '../../assets/styles/styles'
import {
  Text,
  Absolute,
  Flex,
  FlexCell,
  Icon,
  Image,
  Relative,
} from '../../assets/styles/components'
import dummy from '../../utils/dummy'
import { Link } from 'react-router-dom'
import Badge from '../Badge/Badge'
import { withContext } from '../../utils/helper'
import ajaxOrder from '../../routes/Order/ajax'
import moment from 'moment'

class User extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      loading: false,
      errordata: false,
      data: [],
    }
    this.fetchData = this.fetchData.bind(this)
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
    // this.fetchData()
  }
  componentDidUpdate(prevProps) {
    // if (
    //   !prevProps.context.state.refetchOrder &&
    //   this.props.context.state.refetchOrder
    // ) {
    //   this.fetchData()
    //   this.props.context.update('refetchOrder', false)
    // }
  }

  async fetchData() {
    const { token, refetchOrder } = this.props.context.state
    if (!refetchOrder) return false
    this.setState({ loading: true, errordata: false })

    let format = new Date()
    let firstDay = new Date(format.getFullYear(), format.getMonth(), 1)
    let lastDay = new Date(format.getFullYear(), format.getMonth() + 1, 0)

    let start = moment(firstDay).format('YYYY-MM-DD')
    let end = moment(lastDay).format('YYYY-MM-DD')

    let res = await ajaxOrder.getData('', start, end, token)
    if (!res.success) {
      this.setState({ loading: false, errordata: true })
      return false
    }
    let data = res.data
    this.setState({
      data,
      loading: false,
    })
    this.props.context.update('orderCount', data.length)
    this.props.context.update('refetchOrder', false)
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }
  handleClickOutside = event => {
    if (
      this.state.open &&
      this.wrapper &&
      !this.wrapper.contains(event.target)
    ) {
      this.setState({ open: false })
    }
  }
  toggle = () => {
    this.setState({ open: !this.state.open })
  }
  logout = () => {
    this.props.context.logout()
  }
  render() {
    const { loading, errordata, data, open } = this.state
    const { state } = this.props.context
    return (
      <Relative>
        <UserWrapper active={open} onClick={this.toggle}>
          <Image
            src={state.customer.avatar}
            width="30px"
            height="30px"
            borderRadius="50%"
          />
          <Text
            fontSize="15px"
            margin="0 0 0 10px"
            style={{ height: 22, overflow: 'hidden' }}
          >
            {state.customer.last_name
              ? state.customer.name + ' ' + state.customer.last_name
              : state.customer.name}
          </Text>
        </UserWrapper>
        {open ? (
          <ActionWrapper ref={ref => (this.wrapper = ref)}>
            <Image
              src={state.customer.avatar}
              width="80px"
              height="80px"
              borderRadius="50%"
            />
            <Text fontSize="16px" fontWeight="600" margin="10px 0 10px">
              {state.customer.last_name
                ? state.customer.name + ' ' + state.customer.last_name
                : state.customer.name}
            </Text>
            {/* <Link to="/profile">
              <Text
                textAlign="center"
                textDecoration="underline"
                fontSize="12px"
                margin="0 0 10px"
              >
                Edit profil
              </Text>
            </Link> */}
            <UserMenuList to="/order">
              Order{' '}
              <Badge
                count={state.orderCount}
                withNumber
                right="0px"
                bottom="13px"
                top="auto"
              />
            </UserMenuList>
            <UserMenuList to="/profile">Edit Profile</UserMenuList>
            {/* <UserMenuList>Voucher &amp; Kupon Saya</UserMenuList>
            <UserMenuList>Wishlist</UserMenuList> */}
            <Text
              textAlign="center"
              margin="10px 0 0"
              cursor="pointer"
              onClick={this.logout}
            >
              Logout
              <i className="fa fa-sign-out" style={{ marginLeft: 5 }} />
            </Text>
          </ActionWrapper>
        ) : null}
      </Relative>
    )
  }
}

export default withContext(User)
