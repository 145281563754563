import React, { Fragment } from 'react'
import { Wrapper, WrapperHelp, WrapperWa, WrapperButtonSocial } from './styles'
import { Image } from '../../assets/styles/components'
import ModalHelp from './ModalHelp'

class Help extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
    }
  }
  render() {
    return (
      <Fragment>
        <WrapperButtonSocial>
        <Wrapper>
          <Image
            src={require('../../assets/images/icon-cs.png')}
            width="32px"
            height="32px"
            margin="0 10px 0 0"
          />
          Need Help?
        </Wrapper>
        <WrapperHelp className='button-social' onClick={() => this.setState({ modal: true })}>
          <Image
            src={require('../../assets/images/icon-cs.png')}
            width="28px"
            height="28px"
            margin="0 10px 0 0"
          />
          Email
        </WrapperHelp>
        <WrapperWa className='button-social'>
        <a href="https://api.whatsapp.com/send?phone=6282111206399&text=Untuk Memulai Chat Silahkan Isi Data Berikut :%0D%0ANama :%0D%0AEmail : &source=&data=&app_absent=" target="_blank" style={{ textDecoration: 'none', color: 'white' }}>
          <Image
            src={require('../../assets/images/wa_icon.png')}
            width="28px"
            height="28px"
            margin="0 10px 0 0"
          />
          Whatsapp
        </a>
        </WrapperWa>
        </WrapperButtonSocial>
        <ModalHelp
          open={this.state.modal}
          close={() => this.setState({ modal: false })}
        />
      </Fragment>
    )
  }
}

export default Help
