import styled from '@emotion/styled'
import { blue, white, monserrat } from '../../assets/styles/styles'

const Wrapper = styled.div`
  position: fixed;
  right: 20px;
  bottom: 20px;
  padding: 5px 15px;
  border-radius: 15px;
  font-family: ${monserrat};
  font-weight: bold;
  font-size: 14px;
  background-color: #019CC3;
  color: ${white};
  cursor: pointer;
  z-index: 10;
`

const WrapperButtonSocial = styled.div`
  .button-social{
    display:none
  }
  
  &:hover .button-social{
    display:block
  }
`

const WrapperHelp = styled.div`
  position: fixed;
  right: 20px;
  bottom: 65px;
  padding: 5px 15px;
  border-radius: 15px;
  font-family: ${monserrat};
  font-weight: bold;
  font-size: 14px;
  background-color: #019CC3;
  color: ${white};
  cursor: pointer;
  z-index: 10;
  animation: fadeIn 0.5s;
`
const WrapperWa = styled.div`
  position: fixed;
  right: 20px;
  bottom: 105px;
  padding: 5px 15px;
  border-radius: 15px;
  font-family: ${monserrat};
  font-weight: bold;
  font-size: 14px;
  background-color: #019CC3;
  color: ${white};
  cursor: pointer;
  z-index: 10;
  animation: fadeIn 0.5s;
`

export { Wrapper, WrapperHelp, WrapperWa, WrapperButtonSocial }
