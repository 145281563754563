// color
const white = '#fff'
const black = '#4a4a4a'
const title = '#4a4a4a'
const paragraph = '#4a4a4a'
const greybg = '#f1f2f8'
const blue = '#009ec2'
const bluesky = '#6eb1ff'
const link = '#005eb8'
const tablecellodd = '#fbfbfe'
const red = '#d0021b'
const grey = '#fbfbfe'
const grey2 = '#c6cbd4'
const green = '#82bb2c'
const pink = '#fb4e8d'
const orange = '#f27b3a'
const border = '#999'
const border2 = '#bdc3c7'
const background = '#f6f8fb'

// fonts
const gaegu = "'Gaegu', cursive"
const monserrat = "'Montserrat', sans-serif"
const roboto = "'Roboto', sans-serif"
const opensans = "'Open Sans', sans-serif"
const sourcesanspro = "'SourceSansPro', sans-serif"

const breakpoints = ['767px', '992px', '1200px']

export {
  //color
  white,
  black,
  title,
  paragraph,
  greybg,
  blue,
  bluesky,
  link,
  tablecellodd,
  red,
  grey,
  grey2,
  green,
  pink,
  border,
  border2,
  background,
  orange,
  // fonts
  gaegu,
  monserrat,
  roboto,
  opensans,
  sourcesanspro,
  breakpoints,
}
