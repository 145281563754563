import React, { Fragment, useContext } from 'react'
import Modal from '../Modal/Modal'
import Spinner from './Spinner'
import { Text, Image, Relative } from '../../assets/styles/components'
import { withContext } from '../../utils/helper'
import { AppContext } from '../../context/AppContext'

const DandanLoader = () => {
  const { state } = useContext(AppContext)
  return (
    <Fragment>
      {/* <ModalOverlay className="open" color="rgba(255, 255, 255, 0.95)" /> */}
      {/* <div className="dandan-wrapper">
        <Absolute top="50%" left="50%" transform="translate(-50%,-50%)">
          <Image src={require('../../assets/images/logo-new.png')} width="200px" />
        </Absolute>
        <div className="loader"></div>
        <Spinner className="fa-3x" />
          <Text
            fontWeight="bold"
            fontSize="16px"
            textAlign="center"
            margin="10px 0 0"
          >
            loading...
          </Text>
      </div> */}
      <Modal
        open={state.showDandanLoader}
        overlayColor="rgba(255, 255, 255, 1)"
        width="auto"
        padding="15px 30px"
        backgroundColor="transparent"
        overlayzIndex={31}
      >
        <Relative>
          <Image
            src={require('../../assets/images/logo-new1.png')}
            width="200px"
            margin="0 auto 20px"
            display="block"
            className="dandan-loader"
          />
        </Relative>
        {/* <Text textAlign="center">
          <Spinner className="fa-2x" />
        </Text> */}
      </Modal>
    </Fragment>
  )
}

export default DandanLoader
